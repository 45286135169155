import { Input, Select } from 'antd'
import { FC, useContext, useState } from 'react'

import { Col } from '../../../Col'
import { Divider } from '../../../Divider'
import { Row } from '../../../Row'
import { FormItem } from '../../../components/Form'
import { FiltersContext } from './FiltersContext'
import style from './style.m.less'
import { Filter } from './types'
import { onlyDigits } from '../../../utils'

enum NumberFilterType {
    NUMBER = 'number',
    NUMBER_RANGE = 'number_range',
}

type DateFilterProps = Omit<Filter, 'filterType'> & { rules?: unknown[] }

export const NumberFilter: FC<DateFilterProps> = ({ label, name, divider = false, normalize = onlyDigits }) => {
    const { form } = useContext(FiltersContext)
    const [currentType, setCurrentType] = useState(NumberFilterType.NUMBER_RANGE)

    const onFilterTypeChange = (type: NumberFilterType): void => {
        form?.resetFields([`${name}`])
        setCurrentType(type)
    }

    return (
        <FormItem className={style.filtersFormItem}>
            <Row gutter={10} align="middle">
                <Col span={6}>
                    <span className={style.filtersItemLabel}>{label}</span>
                </Col>
                <Col span={6}>
                    <Select
                        className={style.filtersTypeSelect}
                        bordered={false}
                        onChange={onFilterTypeChange}
                        defaultValue={NumberFilterType.NUMBER_RANGE}
                        size="middle"
                    >
                        <Select.Option value="number">Число</Select.Option>
                        <Select.Option value="number_range">Диапазон</Select.Option>
                    </Select>
                </Col>
                <Col span={12}>
                    {currentType === NumberFilterType.NUMBER && (
                        <FormItem 
                          name={name} 
                          normalize={normalize}
                          >
                            <Input style={{ width: '100%' }} size="middle" required={false} />
                        </FormItem>
                    )}
                    {currentType === NumberFilterType.NUMBER_RANGE && (
                        <FormItem name={name}>
                            <Input.Group>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <FormItem 
                                          name={[`${name}`, 'gte']} 
                                          normalize={normalize}
                                          >
                                            <Input style={{ width: '100%' }} size="middle" />
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem 
                                          name={[`${name}`, 'lte']}                                           
                                          normalize={normalize}
                                          >
                                            <Input style={{ width: '100%' }} size="middle" />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Input.Group>
                        </FormItem>
                    )}
                </Col>
            </Row>
            {divider && <Divider padding={[16, 0, 0, 0]} margin={0} />}
        </FormItem>
    )
}
