import { FC, ReactElement, useContext } from 'react'

import { Col } from '../../../Col'
import { Divider } from '../../../Divider'
import { Row } from '../../../Row'
import { FormItem, WrappedAutocomplete } from '../../../components/Form'
import { FiltersContext } from './FiltersContext'
import style from './style.m.less'
import { DataProviderResponse, Filter } from './types'

type AutocompleteFilterProps = Omit<Filter, 'filterType'>

export const AutocompleteFilter: FC<AutocompleteFilterProps> = ({
    label,
    name,
    divider = false,
    ...props
}) => {
    const { form } = useContext(FiltersContext)

    const onSelectHandler = (selected: DataProviderResponse[]) => {
        form?.setFieldsValue({ [`${name}`]: selected })
    }

    return (
        <FormItem className={style.filtersFormItem}>
            <Row gutter={10} align="middle">
                <Col span={6}>
                    <span className={style.filtersItemLabel}>{label}</span>
                </Col>

                <Col span={18}>
                    <WrappedAutocomplete
                        dataProvider={props.dataProvider}
                        renderElement={props.renderElement as ReactElement}
                        onSelect={onSelectHandler}
                        defaultOptions={form?.getFieldValue(name)}
                        selectProps={{ mode: 'multiple', placeholder: props.placeholder }}
                        name={name}
                    />
                </Col>
            </Row>
            {divider && <Divider padding={[16, 0, 0, 0]} margin={0} />}
        </FormItem>
    )
}
