import { FormItemProps } from 'antd'
import React from 'react'

export type FilterRecord = Record<string, unknown>

export enum FilterType {
    DATE = 'date',
    DATE_RANGE = 'date_range',
    AUTOCOMPLETE = 'autocomplete',
    SELECT = 'select',
    NUMBER = 'number',
    NUMBER_RANGE = 'number_range',
    TEXTINPUT = 'textinput',
}

export const FilterTypeSingle: {
    [key in keyof FilterTypeByName]: FilterType
} = {
    [FilterType.DATE_RANGE]: FilterType.DATE,
    [FilterType.NUMBER_RANGE]: FilterType.NUMBER,
}

export type SelectData = {
    label: string
    value: string
    key?: string
}
export type DataProviderResponse = SelectData & { [key: string]: unknown }
export type DataProvider = (value?: unknown) => Promise<DataProviderResponse[]>

export interface Filter extends Pick<FormItemProps, 'normalize'> {
    label: string
    name: string
    filterType: FilterType
    dataProvider?: DataProvider
    options?: SelectData[]
    renderElement?: React.ReactNode
    divider?: boolean
    placeholder?: string
    onlyFuture?: boolean
}

export type FiltersProps = {
    filters: Filter[]
    onApplyFilters: (val?: FilterRecord) => void
    isQs?: boolean
}

export type FiltersContextProps = {
    togglePopupShow: (v: boolean) => void
} & FiltersProps

export type FilterTypeByName = Record<string, FilterType>
