import { FC, useContext } from 'react'

import { Col } from '../../../Col'
import { Divider } from '../../../Divider'
import { Row } from '../../../Row'
import { FormItem, WrappedSelect } from '../../../components/Form'
import { FiltersContext } from './FiltersContext'
import style from './style.m.less'
import { Filter } from './types'

type SelectFilterProps = Omit<Filter, 'filterType'>

export const SelectFilter: FC<SelectFilterProps> = ({ label, name, divider = false, ...props }) => {
    const { form } = useContext(FiltersContext)

    const onSelectHandler = (selected: string | string[]) => {
        form?.setFieldsValue({
            [`${name}`]: Array.isArray(selected) ? selected : selected.split(','),
        })
    }

    return (
        <FormItem className={style.filtersFormItem}>
            <Row gutter={10} align="middle">
                <Col span={6}>
                    <span className={style.filtersItemLabel}>{label}</span>
                </Col>

                <Col span={18}>
                    <WrappedSelect
                        mode="multiple"
                        allowClear
                        defaultValue={[]}
                        onChange={onSelectHandler}
                        options={props.options}
                        name={name}
                        size="middle"
                        placeholder={props.placeholder}
                    />
                </Col>
            </Row>
            {divider && <Divider padding={[16, 0, 0, 0]} margin={0} />}
        </FormItem>
    )
}
